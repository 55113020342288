import { Global } from "@emotion/react";

import brandFont from "./fonts";
import { mq } from "./mediaqueries";
import { textColor, bodyBackgroundColor } from "./colors";

export const marginVertical = ['clamp(20px, 4.8828125vh, 50px)', 'clamp(20px, 4.8828125vh, 50px)'];
export const marginHorizontal = ['clamp(20px, 6.944444444vw, 100px)', 'clamp(100px, 12vw, 800px)'];

const globalStyles = (
  <Global
    styles={{
      "*": {
        boxSizing: "border-box",
      },

      "html, body": {
        margin: 0,
        padding: 0,
      },

      body: {
        fontSize: "1rem",
        color: textColor,
        lineHeight: 1.5,
        height: "100vh",
        fontFamily: brandFont,
        background: bodyBackgroundColor,
        width: "100vw",
        minHeight: "100vh",

        [mq[2]]: {
          fontSize: "1.125rem",
        },

        [mq[3]]: {
          fontSize: "1.25rem",
        },
      },

      "#__next": {
        display: "flex",
        minHeight: "100vh",
        flexDirection: "column",
        justifyContent: "flex-start",
        position: 'relative',
        width: '100vw',
        padding: `${marginVertical[0]} ${marginHorizontal[0]}`,

        [mq[3]]: {
          padding: `${marginVertical[1]} ${marginHorizontal[1]}`,
        }
      },

      main: {
        overflowX: "hidden",
        minHeight: "50vh",
      },

      p: {
        marginTop: 0
      },

      ul: {
        padding: 0,
        margin: 0,
        listStyle: "none"
      },

      button: {
        border: 0,
        fontSize: "inherit",
        fontFamily: "inherit",
        backgroundColor: "transparent",
        cursor: "pointer",

        "&:focus": {
          outline: 0,
        },
      },

      img: {
        width: "100%",
        objectFit: 'cover'
      },

      a: {
        textDecoration: "none",
        color: "inherit"
      },

      h1: {
        fontSize: "3rem",
        fontWeight: "normal",
        lineHeight: 1,
        margin: 0,
        padding: 0,

        [mq[1]]: {
          fontSize: "5rem",
        },

        [mq[2]]: {
          fontSize: "6rem",
        },
      },

      h2: {
        fontSize: "1.25rem",
        fontWeight: 300,
        lineHeight: "1.3em",

        [mq[1]]: {
          fontSize: "1.75rem",
        },

        [mq[2]]: {
          fontSize: "2.25rem",
        },

        [mq[3]]: {
          fontSize: "2.5rem",
        },
      },
    }}
  />
);

export default globalStyles;