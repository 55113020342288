import "../assets/fonts/stylesheet.css";
import globalStyles from '../assets/styles/globals'

function MyApp({ Component, pageProps }) {
  return (
    <>
      {globalStyles}
      <Component {...pageProps} />
    </>  
  )
}

export default MyApp
