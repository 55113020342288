export const white = "#FCFCFC";
export const black = "#1D1D1D";

export const grey = "#BFBFBF";
export const greyDark = "#7C7C7C";
export const greyLight = "#EAEAEA";

export const textColor = black;
export const iconColor = black;

export const bodyBackgroundColor = white;

// Kaleidoscope colors

